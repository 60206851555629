<template>
  <Main />
</template>

<script>
import Main from "@/layouts/Main.vue";
export default {
  components: { Main },
  created() {
    this.$store.dispatch("getCourseDetails", this.$route.params.courseId);
  }
};
</script>

<style lang="scss">
.vc-wrapper {
  height: 100%;
  padding: 2% 6%;
  .vc-attendance__list,
  .vc-management__list {
    background: #ffffff;
    border: 1px solid #b2b2b2;
    .vcm-list__table {
      border: none;
      padding: 30px 0;
      min-height: 450px;
      .vcm-list__table--tr {
        th {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.1px;
          color: #a0a4a8;
          font-family: "Roboto";
          div {
            margin-bottom: 8px;
            font-weight: 500;
            color: #434343;
            &.vc-checkbox {
              display: flex;
            }
          }
        }
      }
      thead,
      tbody {
        td,
        th {
          &:first-child {
            padding-left: 30px;
          }
          &:last-child {
            padding-right: 30px;
          }
        }
      }
      tbody {
        tr {
          &:hover {
            background: rgba(196, 196, 196, 0.1);
            .vcm-list__table--attendance {
              color: #0043ac;
            }
          }
          td {
            font-family: "Open Sans";
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.2px;
            color: #52575c;
            vertical-align: middle;
            padding: 0.75rem 0.3rem;
            p {
              margin-bottom: 0;
            }
          }
          .vcm-list__table--name {
            font-family: "Open Sans";
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            color: #25282b;
            margin-bottom: 0;
            max-width: 380px;
          }
          .vcm-list__table--attendance {
            color: #a0a0a0;
            text-decoration: none;
            cursor: pointer;
            font-weight: 600;
          }
        }
      }
    }
    .vc-pagination {
      margin: 0px 30px;
      border-top: 1px solid #e8e8e8;
      padding: 30px 0;
    }
    .loader {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 400px;
    }
    .vc__access-denied {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 450px;
      h5 {
        font-family: "Open Sans";
        font-weight: 400;
        font-size: 24px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #161616;
        padding: 26px 0;
        margin: 0;
      }
      p {
        width: 30%;
        margin-bottom: 26px;
        @include p;
      }
    }
  }
}
</style>
